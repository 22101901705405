@import './colors';
@import './typography';

html, body {
  background-color: $white;
  color: $black;
  font-family: $sans;
  font-size: 16px;
  font-weight: $regular;
  line-height: 1.5;
  position: relative;
  height: 100%;
}

*, *::before, *::after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: $transparent;
}

a, a:hover {
  color: inherit;
  text-decoration: none;
}

img {
  max-width: 100%;
}

::selection {
  background-color: $yellow;
}

@mixin clearfix {
  &::after {
    clear: both;
    content: ' ';
    display: block;
  }
}
