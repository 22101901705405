@import '../styles/colors';
@import '../styles/typography';
@import '../styles/media_queries';

.footer-wrapper {
  background: $yellow;
  padding-bottom: 8rem;
  padding-top: 11.5rem;
  position: relative;
  transition: 0.3s ease opacity;

  &::before {
    background-image: linear-gradient(
      to right bottom,
      $white 0%,
      $white 50%,
      $yellow 50%,
      $yellow 100%
    );
    content: ' ';
    height: 10rem;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }

  footer {
    display: flex;
    position: relative;
    z-index: 2;
  }

  .logo-area, .right-side {
    flex: 1;
  }

  .copy {
    margin-top: 1.5rem;
    opacity: 0.5;
  }

  .notice {
    opacity: 0.5;
    font-size: 12px;
    border: 1px solid rgba($black, 0.5);
    padding: 0.5rem 1rem;
    margin-top: 1.5rem;
    max-width: 22.5rem;
  }

  .logo-area, .columns {
    align-items: flex-start;
    display: flex;
    margin: 0 1.5rem;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .logo-area {
    flex-direction: column;

    .logo {
      margin-bottom: 1.5rem;
    }
  }

  .email-form {
    width: 15rem;
  }

  .column {
    flex: 1;

    h5 {
      margin-bottom: 0.5rem;
    }

    h6 {
      opacity: 0.5;
      transition: 0.3s ease opacity;

      + h5 {
        margin-top: 1.5rem;
      }

      &:hover {
        opacity: 1;
      }
    }
  }
}

@media screen and (max-width: $phone-landscape-max) {
  .footer-wrapper {
    padding-bottom: 3rem;

    footer {
      flex-direction: column;
    }

    .email-form {
      margin-bottom: 3rem;
      width: 100%;
    }

    .logo-area, .columns {
      margin: 0 0 1.5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
