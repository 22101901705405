@import 'media_queries';

// Font weights
$extra-bold: 800;
$bold: 700;
$semi-bold: 600;
$regular: 400;
$light: 300;

$sans: 'Work Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$serif: 'Vollkorn', Georgia, serif;

h1, %h1 {
  font-family: $serif;
  font-size: 5rem;
  font-weight: $bold;
  margin-bottom: 1.5rem;
}

h2, %h2 {
  color: $blue;
  font-family: $serif;
  font-size: 2.5rem;
  font-weight: $regular;
  line-height: 1.5;
  margin-bottom: 3rem;

  &.close {
    margin-bottom: 0.5rem;
  }
}

h3, %h3 {
  color: $blue;
  font-family: $serif;
  font-size: 2rem;
  font-weight: $regular;
  margin-bottom: 1.5rem;
}

h4, %h4 {
  color: $blue;
  font-family: $serif;
  font-size: 1.5rem;
  font-weight: $regular;
}

h5, %h5 {
  font-family: $sans;
  font-size: 1rem;
  font-weight: $semi-bold;
}

%h5-small {
  font-family: $sans;
  font-size: 0.75rem;
  font-weight: $semi-bold;
}

h6, %h6 {
  font-size: 1rem;
  font-weight: $regular;
  line-height: 1.65;
}

%h6-small {
  font-family: $sans;
  font-size: 0.75rem;
  font-weight: $regular;
  line-height: 1.5;
}

.light {
  color: $gray;
}

.very-light {
  color: $gray;
}

p, %p {
  color: $black;
  font-family: $sans;
  font-size: 1.1rem;
  font-weight: $regular;
  line-height: 1.65;
  margin-bottom: 1.1rem;

  &.small {
    font-size: 0.875rem;
  }
}

strong {
  font-weight: $bold;
}

.text-content, %text-content {
  ul {
    list-style: disc outside;
    margin-left: 1.5rem;

    li {
      font-size: 1.1rem;
      margin-bottom: 0.75rem;
      padding-left: 1.5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  a:not([class~="button"]) {
    text-decoration: underline;
    transition: 0.3s ease color;

    &:hover {
      color: $blue;
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: $phone-landscape-max) {
  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.5rem;

    &.m-h2 {
      font-size: 2rem;
    }

    &.m-h4 {
      font-size: 1.25rem;
    }
  }

  h4 {
    font-size: 1.25rem;
  }

  h5, h6, p {
    font-size: 1rem;
  }
}
