@import './colors';

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.Spinner {
  animation: spin 1s linear infinite;
  border-top: 3px solid $white;
  border-left: 3px solid rgba($white, 0.3);
  border-right: 3px solid rgba($white, 0.3);
  border-bottom: 3px solid rgba($white, 0.3);
  border-radius: 50%;
  height: 1rem;
  width: 1rem;

  &.dark {
    border-top: 3px solid $black;
    border-left: 3px solid rgba($black, 0.3);
    border-right: 3px solid rgba($black, 0.3);
    border-bottom: 3px solid rgba($black, 0.3);
  }
}

