@import '../styles/colors';
@import '../styles/media_queries';
@import '../styles/typography';
@import '../components/Button.scss';


.Advising {
  p {
    a {
      text-decoration: underline;
      transition: 0.3s ease color;

      &:hover {
        color: $blue;
      }
    }

    & + p {
      margin: 1.5rem 0 -0.25rem;
    }
  }

  .jumplink {
    padding: 0.25rem 0;
    margin-left: 1.25rem;
    font-weight: $bold;
    list-style: decimal;
    pointer-events: none;

    &:hover {
      // color: $blue;
      text-decoration: underline;
    }

    a {
      pointer-events: auto;
      display: block;
      margin-left: -1.5rem;
      padding-left: 1.5rem;
    }
  }

  .question {
    padding: 3rem 0;

    h5 {
      margin-top: -0.5rem;
      margin-bottom: 0.5rem;
    }

    &:first-child {
      margin-top: -3rem;
    }

    .skip-question {
      display: inline-block;
      margin-top: 1rem;
    }
  }

  .suggestions {
    padding: 1.5rem 0 0;
    color: $gray;
    display: flex;
    align-items: top;

    label {
      padding: 0.5rem 0.25rem 0.5rem 0;
    }

    .suggestions-list {
      display: flex;
      flex-wrap: wrap;
    }

    .suggestion {
      @extend %line-button;
      border-radius: 4px;
      white-space: nowrap;
      color: $blue;
      cursor: pointer;
      font-size: 0.875rem;
      font-weight: $regular;
      margin: 0.25rem;
      padding: 0.25rem 0.5rem;
      transition: 0.3 ease background-color;
      user-select: none;

      &:hover, &:focus {
        background-color: rgba($blue, 0.1);
      }
    }
  }
}

.single-choice:not(.none-selected) {
  input + label {
    border-color: $light-gray;
    color: $gray;
  }
}

.multiple-choice, .single-choice {
  display: flex;
  flex-wrap: wrap;
  margin: -0.5rem;

  .option {
    padding: 0.5rem;
    width: 50%;
  }

  input {
    opacity: 0;
    filter: alpha(opacity=0);
    position: absolute;

    & + label {
      border: 2px solid $blue;
      padding: 0.5rem 1rem;
      color: $blue;
      display: block;
      border-radius: 4px;
      cursor: pointer;
      user-select: none;

      .label, .description {
        display: block;
      }

      .description {
        @extend %h6-small;
        color: $gray;
      }
    }

    &:focus + label, + label:hover {
      background-color: rgba($blue, 0.1);
    }
  }

  input:checked + label {
    background-color: $blue;
    border-color: $blue;
    color: $white;
  }
}

@media screen and (max-width: $phone-landscape-max) {
  .Advising {
    .multiple-choice, .single-choice {
      .option {
        .label {
          font-size: 0.875rem;
        }
        .description {
          font-size: 0.75rem;
        }
      }
    }
    .m-hidden {
      display: none;
    }
  }
}
