@import '../../styles/colors';
@import '../../styles/typography';
@import '../../styles/media_queries';

.RecommendationList {
  .recommendation {
    margin-bottom: 3rem;
    border: 2px solid $light-gray;
    border-radius: 8px;
    padding: 1rem;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    > label {
      @extend %h6-small;
      padding: 0 0.5rem;
      border-top-right-radius: 4px;
      border-bottom-left-radius: 8px;
      position: absolute;
      top: 0;
      right: 0;
      background-color: $light-gray;
      color: $gray;
      user-select: none;
    }

    h4 {
      margin-bottom: 0.5rem;

      .title.with-subtitle {
        margin-right: 1rem;
      }

      .h5 {
        display: inline;
        color: $gray;
      }
    }

    .button-group {
      margin: 1rem 0 1.5rem;

      .button {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
    }

    .see-more-button {
      padding: 0;
      display: block;
      margin: -1rem 0 1.5rem;
    }

    p {
      font-size: 1rem;
      line-height: 1.5;
      margin-right: 0;
      max-height: 3rem;
      overflow: hidden;
      padding-right: 1em;
      position: relative;
      text-align: justify;

      &:before {
        content: '...';
        position: absolute;
        right: 0;
        bottom: 0;
      }

      &:after {
        content: '';
        position: absolute;
        right: 0;
        width: 1em;
        height: 1em;
        margin-top: 0.2em;
        background: white;
      }

      &.show-all {
        max-height: 100rem;
      }
    }

    .description-wrapper {
      display: flex;
      align-items: flex-start;
      margin-top: 0.5rem;
    }

    .factors {
      display: flex;
      margin: 0 -0.75rem -1.5rem;

      .endorsement {
        font-size: 0.5rem;
        display: block;
        color: $gray;
        text-transform: uppercase;
      }

      li {
        @extend %h5-small;
        border-radius: 0;
        border-left: 2px solid $light-gray;
        margin-bottom: 0.5rem;
        padding: 0.5rem 0.75rem 0.25rem;
        line-height: 1.5;
        width: 30%;
        display: none;

        &:first-child {
          border-left: 0;
        }

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          display: block;
        }
      }
    }

    .justifications {
      @extend %h5-small;
      background: $light-gray;
      color: $gray;
      padding: 0.5rem 1rem;
      margin: 1rem -1rem -1rem;
      display: flex;

      label {
        white-space: nowrap;
              }

      .list {
        margin-left: 0.5rem;
        font-weight: $regular;
      }
    }
  }
}

@media screen and (max-width: $phone-landscape-max) {
  .RecommendationList {
    .recommendation .justifications {
      display: block;

      .list {
        margin-left: 0.25rem;
      }
    }
  }
}
