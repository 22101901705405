@import 'media_queries';
@import 'animations';

$text-content-width: 45rem;
$content-width: 60rem;
$content-padding: 1.5rem;
$nav-height: 6rem;
$nav-content-height: 3rem;


section {
  padding: 4rem 0;
}

.content {
  margin: 0 auto;
  max-width: $content-width;
  padding: 0 $content-padding;
  width: 100%;

  > header, > .split {

    .text, .image {
      flex: 1;
      margin: 0 1.5rem;

      &:last-child {
        margin-right: 0;
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }

  + .content {
    margin-top: 3rem;
  }

  &.tight {
    padding-bottom: 0;

   + .content {
      margin-top: 0;
      padding-top: 0;
   }
  }

  > .centered {
    margin: 0 auto;
    max-width: 30rem;
    text-align: center;
  }
}

.pillars-wrapper {
  display: flex;

  .pillars-cta {
    flex-shrink: 0;
    margin-right: 3rem;
    position: relative;
    width: 15rem;
  }

  .pillar {
    margin-bottom: 6rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.grid {
  margin: 0 -1.5rem;
  display: flex;
  flex-wrap: wrap;

  .half {
    width: 50%;
    padding: 1.5rem;
  }

  .box {
    width: 33.33%;
    padding: 1.5rem;
  }
}

.text-content, %text-content {
  max-width: $text-content-width;
}

header {
  @extend %text-content;
  margin-top: $nav-height;

  > h2 {
    animation: $a-fade-in-up-header;
    color: $blue;
    margin-bottom: 1.5rem;

    &:last-child {
      margin: 0;
    }
  }
}

@media screen and (max-width: $tablet-landscape-max) {
  .grid .box {
    width: 50%;
  }
}

@media screen and (max-width: $phone-landscape-max) {

  // scss-lint:disable ImportantRule
  .about, .events, .home {
    .last {
      margin-bottom: 3rem;
      min-height: auto !important;
    }
  }
  // scss-lint:enable ImportantRule

  .grid .box {
    width: 100%;
    padding: 0 1.5rem;
  }

  section {
    padding: 1.5rem 0;
  }

  .pillars-wrapper {
    flex-direction: column;

    .pillars-cta {
      margin-bottom: 1.5rem;
      margin-right: 0;
      width: 100%;
    }

    .pillar {
      margin-bottom: 3rem;
    }
  }
}
