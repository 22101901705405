form {
  padding: 0;
}

input {
  -webkit-appearance: none;
  border: 0;
  padding: 0;

  &[type=email], &[type=text] {
    background-color: rgba($white, 0.5);
    border: 2px solid $blue;
    border-radius: 4px;
    color: $blue;
    font-family: $sans;
    font-size: 1rem;
    margin: 0.75rem 0;
    outline: none;
    padding: 0.5rem 0.75rem;
    transition: 0.3s ease background-color;
    width: 100%;

    &::placeholder {
      color: $dark-gray;
      font-family: $sans;
      font-size: 1rem;
      opacity: 0.5;
      transition: 0.3s ease opacity;
      user-select: none;
    }

    &:active, &:focus {
      background-color: $white;

      ::placeholder {
        opacity: 1;
      }
    }
  }
}

.typeform-wrapper {
  top: 6rem;
  bottom: 0;
  width: 100%;
  position: absolute;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
}
