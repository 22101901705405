@import '../../styles/colors';
@import '../../styles/typography';

.DefinedTerm {
  @extend %h5-small;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 0.75rem;
  line-height: 1.5;
  position: relative;
  cursor: help;
  display: inline-block;

  .hover-card {
    background-color: $white;
    border-radius: 0.75rem;
    box-shadow: 0 0 10px rgba($black, 0.1);
    display: none;
    font-size: 0.75rem;
    left: -0.75rem;
    padding: 0.5rem 0.75rem;
    position: absolute;
    right: -2px;
    top: 110%;
    width: 20rem;
    z-index: 100;

    .title {
      @extend %h5-small;
    }

    .text {
      @extend %h6-small;
    }
  }

  &:hover .hover-card {
    display: block;
  }

  &:last-child {
    margin-right: 0;
  }
}
