@import '../../styles/responsive';

.stapled {
  position: fixed;
  top: 48px;

  &:last-child {
    margin-bottom: 0;
  }
}

.stapled-bottom {
  position: absolute;
  bottom: 0px;
}

// .pillars-wrapper {
//   flex-direction: column;

//   .pillars-cta {
//     margin-bottom: 1.5rem;
//     margin-right: 0;
//     width: 100%;
//   }

//   .pillar {
//     margin-bottom: 3rem;
//   }
// }

@media screen and (max-width: $phone-landscape-max) {
  .stapled, .stapled-bottom {
    position: relative;
    top: auto;
  }
}
