// scss-lint:disable SpaceBeforeBrace

// Header 1
$delay-header-1: 0.3s;
$length-header-1: 0.75s;
$total-header-1: ($delay-header-1 + $length-header-1);
$p-header-1: ($delay-header-1 / $total-header-1 * 100);

@keyframes home-fade-in-up-header-1 {
  0%              { opacity: 0; transform: translate3d(0, 1rem, 0); }
  #{$p-header-1}% { opacity: 0; transform: translate3d(0, 1rem, 0); }
  100%            { opacity: 1; transform: translate3d(0, 0, 0); }
}

$a-home-fade-in-up-header-1: home-fade-in-up-header-1 $total-header-1 ease;

// Header 2
$delay-header-2: ($total-header-1 + 0.75s);
$length-header-2: 0.75s;
$total-header-2: ($delay-header-2 + $length-header-2);
$p-header-2: ($delay-header-2 / $total-header-2 * 100);

@keyframes home-fade-in-up-header-2 {
  0%              { opacity: 0; transform: translate3d(0, 1rem, 0); }
  #{$p-header-2}% { opacity: 0; transform: translate3d(0, 1rem, 0); }
  100%            { opacity: 1; transform: translate3d(0, 0, 0); }
}

// Navbar
$a-home-fade-in-up-header-2: home-fade-in-up-header-2 $total-header-2 ease;

$delay-nav: ($total-header-2 + 0.75s);
$length-nav: 0.75s;
$total-nav: ($delay-nav + $length-nav);
$p-nav: ($delay-nav / $total-nav * 100);

@keyframes home-fade-in-down-nav {
  0%         { opacity: 0; transform: translate3d(0, -1rem, 0);}
  #{$p-nav}% { opacity: 0; transform: translate3d(0, -1rem, 0);}
  100%       { opacity: 1; transform: translate3d(0, 0, 0);}
}

$a-home-fade-in-down-nav: home-fade-in-down-nav $total-nav ease;

// Content
$delay-content: ($total-header-2 + 0.75s);
$length-content: 0.75s;
$total-content: ($delay-content + $length-content);
$p-content: ($delay-content / $total-content * 100);

@keyframes home-fade-in-up-content {
  0%             { opacity: 0; transform: translate3d(0, 1rem, 0); }
  #{$p-content}% { opacity: 0; transform: translate3d(0, 1rem, 0); }
  100%           { opacity: 1; transform: translate3d(0, 0, 0); }
}

$a-home-fade-in-up-content: home-fade-in-up-content $total-content ease;

// Button
$delay-button: ($total-content + 0.3s);
$length-button: 0.3s;
$total-button: ($delay-button + $length-button);
$p-button: ($delay-button / $total-button * 100);

@keyframes home-stroke-button {
  0%            { transform: translate(0, -40%) skew(-20deg) rotate(-5deg) scale(0, 1); }
  #{$p-button}% { transform: translate(0, -40%) skew(-20deg) rotate(-5deg) scale(0, 1); }
  100%          { transform: translate(0, -40%) skew(-20deg) rotate(-5deg); }
}

$a-home-stroke-button: home-stroke-button $total-button ease;

// Headers
@keyframes fade-in-up-header {
  0%   { opacity: 0; transform: translate3d(0, 1rem, 0); }
  50%  { opacity: 0; transform: translate3d(0, 1rem, 0); }
  100% { opacity: 1; transform: translate3d(0, 0, 0); }
}

$a-fade-in-up-header: fade-in-up-header 0.6s ease;

// Button
@keyframes stroke-button {
  0%            { transform: translate(0, -40%) skew(-20deg) rotate(-5deg) scale(0, 1); }
  75%           { transform: translate(0, -40%) skew(-20deg) rotate(-5deg) scale(0, 1); }
  100%          { transform: translate(0, -40%) skew(-20deg) rotate(-5deg); }
}

$a-stroke-button: stroke-button 1.2s ease;

// scss-lint:enable SpaceBeforeBrace
