$blue: #1B465D;
$pink: #DF84FF;
$yellow: #FECF59;
$black: #222222;
$dark-gray: #777777;
$gray: #8B8B8B;
$medium-gray: #CCCCCC;
$light-gray: #E5E5E5;
$ultra-light-gray: #F5F5F5;
$white: #FFFFFF;

$light-yellow: #F7F9E3;
$transparent: rgba(0, 0, 0, 0);
