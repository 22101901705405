@import '../styles/colors';
@import '../styles/typography';
@import '../styles/media_queries';
@import '../styles/animations';
@import '../styles/layout';
@import './Button.scss';

.Navbar {
  color: $blue;
  left: 0;
  margin: 0 auto;
  max-width: $content-width;
  padding: 0 $content-padding;
  position: absolute;
  right: 0;
  top: 0;

  nav {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    height: $nav-height;
    padding: (($nav-height - $nav-content-height) / 2) 0;

    ul {
      align-items: center;
      display: flex;
      height: $nav-content-height;
      z-index: 100;
    }

    li {
      color: $dark-gray;
      margin: 0 1.5rem;
      user-select: none;

      &.selected {
        color: $blue;
      }

      &:last-child {
        margin-right: 0;
      }

      &:first-child {
        margin-left: 0;
      }

      a {
        @extend %highlight-button;
        line-height: $nav-content-height;
      }

      .highlight-button {
        animation: $a-stroke-button;
      }
    }

    .spacer {
      flex: 1;
    }
  }

  .hamburger, .logo {
    user-select: none;
    z-index: 150;
  }

  .logo {
    margin-left: -1rem;
    width: 13rem;
  }

  .hamburger {
    cursor: pointer;
    display: none;
    height: 3.5rem;
    margin-right: -1rem;
    padding: 1rem;
    position: relative;
    transition: 0.5s ease transform;
    width: 3.5rem;

    .ham, .bur, .ger {
      background-color: $blue;
      height: 2px;
      left: 50%;
      position: absolute;
      top: 50%;
      transition: 0.5s ease all;
      width: 1.5rem;
    }

    .ham {
      transform: translate3d(-50%, -50%, 0) translate3d(0, -0.5rem, 0);
    }

    .bur {
      transform: translate3d(-50%, -50%, 0) translate3d(0, 0, 0);
    }

    .ger {
      transform: translate3d(-50%, -50%, 0) translate3d(0, 0.5rem, 0);
    }
  }
}

@media screen and (max-width: $phone-landscape-max) {
  .Navbar nav {
    .hamburger {
      display: block;
    }

    ul {
      background-color: $white;
      bottom: 0;
      flex-direction: column;
      height: auto;
      justify-content: center;
      left: 0;
      opacity: 0;
      pointer-events: none;
      position: fixed;
      right: 0;
      top: 0;
      transform: translate3d(0, -100%, 0);
      transition: 0.5s ease transform, 0s ease opacity 0.5s;

      li {
        margin: 1.5rem 0;
      }

      a {
        color: $blue;
        font-family: $serif;
        font-size: 2rem;
        font-weight: $regular;

        &::after {
          height: 1.5rem;
        }
      }
    }

    &.open {
      ul {
        opacity: 1;
        pointer-events: auto;
        transform: none;
        transition: 0.5s ease transform, 0s ease opacity;
      }

      .hamburger {
        transform: rotate(180deg);

        .ham {
          transform: translate3d(-50%, -50%, 0) rotate(45deg);
        }

        .bur {
          opacity: 0;
        }

        .ger {
          transform: translate3d(-50%, -50%, 0) rotate(-45deg);
        }
      }
    }
  }

  .nav-open {
    overflow: hidden;
  }
}
