@import '../styles/colors';
@import '../styles/typography';

%base-button {
  @extend %h5;
  position: relative;
  text-decoration: none;
  outline: none;
  border: 0;
  user-select: none;
  -webkit-appearance: none;

  &:hover {
    text-decoration: none;
  }

  i {
    margin-right: 0.5rem;

    svg {
      height: 0.75rem;
      width: 0.75rem;
    }
  }
}

.button-group {
  display: flex;
  margin: 1.5rem 0;

  &:last-child {
    margin-bottom: 0;
  }

  &:first-child {
    margin-top: 0;
  }

  &.centered {
    justify-content: center;
  }

  .button, .line-button, .text-button {
    margin: 0 0.5rem;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.button, %button {
  @extend %base-button;
  @extend %h5;
  background-color: $gray;
  border-radius: 4px;
  color: $white;
  cursor: pointer;
  padding: 0.5rem 0.75rem;
  transition: 0.3s ease background-color;
  user-select: none;

  &:hover {
    background-color: $dark-gray;
    color: $white;
  }

  &.disabled {
    background-color: $light-gray;
    color: $gray;
    cursor: default;
  }

  &.full-width {
    width: 100%;
  }
}

.button.blue, %button-blue {
  background-color: $blue;

  &:hover, &:focus {
    background-color: rgba($blue, 0.85);
  }
}

.highlight-button, %highlight-button {
  @extend %h5;
  position: relative;
  transition: 0.3s ease color;
  z-index: 0;

  &.yellow {
    color: $blue;

    &::after {
      background-color: $yellow;
    }
  }

  &::after {
    background-color: $light-gray;
    border-radius: 4px;
    content: ' ';
    height: 1rem;
    left: -0.5rem;
    opacity: 0.7;
    position: absolute;
    right: -1rem;
    top: 50%;
    transform: translate(0, -30%) skew(-20deg) rotate(-5deg) scale(0, 1);
    transform-origin: left center;
    transition: 0.3s ease transform;
    z-index: -1;
  }

  &:hover {
    color: $blue;

    &::after {
      transform: translate(0, -30%) skew(-20deg) rotate(-5deg);
    }
  }
}

.line-button, %line-button {
  @extend %base-button;
  background: $transparent;
  border: 2px solid currentColor;
  padding: 0.5rem 1.5rem;

  &::after {
    background: currentColor;
    bottom: 0.5rem;
    left: 1.5rem;
    right: 1.5rem;
  }
}

.text-button, %text-button {
  @extend %base-button;
  color: $blue;
  transition: 0.3s ease opacity;
  cursor: pointer;
  background-color: $transparent;

  &::after {
    background: currentColor;
    left: 0;
    right: 0;
    top: 100%;
  }

  &:hover {
    opacity: 0.75;
  }

  &.disabled {
    color: $gray;
    cursor: select;
  }
}
